import React,{useState,useEffect} from 'react';
import { Card, Flex, Typography } from 'antd';
import { FileSyncOutlined, HeartOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function Item({ title, text, icon, path }) {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 768px)'); // Define the media query for devices with a minimum width of 768px
    setIsLargeScreen(mediaQuery.matches); // Set initial value based on the media query
    const handleChange = (e) => setIsLargeScreen(e.matches); // Update the state when the media query matches change
    mediaQuery.addListener(handleChange); // Add listener for media query changes

    return () => mediaQuery.removeListener(handleChange); // Clean up listener
  }, []);

  const cardStyle = {
    width: isLargeScreen ? '40%' : '100%', // Set width based on the value of isLargeScreen
    cursor: 'pointer', // Add cursor pointer to indicate it's clickable
  };

  const iconStyle = {
    fontSize: '60px',
    marginLeft: '2%',
    color: '#8A593E',
  };
  const history = useNavigate();

  const handleClick = () => {
    if (path) {
      history(path);
    }
  };
  const titleStyle = {
    color: '#8A593E', // Set color
    fontFamily: 'Inter, sans-serif', // Set font
  };

  return (
    <Card
      hoverable
      style={cardStyle}
      styles={{
        body: {
          padding: 0,
          overflow: 'hidden',
        },
      }}
      onClick={handleClick} // Call handleClick function when card is clicked
    >
      <Flex justify="space-between">
        {icon && React.cloneElement(icon, { style: iconStyle })}
        <Flex
          vertical
          align="flex-end"
          justify="space-between"
        
        >
          <Typography.Title level={5} style={titleStyle}>
            {title}
          </Typography.Title>
          <Typography.Text level={3}>
            {text}
          </Typography.Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export default Item;
