import React, { useState, useEffect } from "react";
import "../../styles/certi.css";
import { Flex, Input, Image, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import Logout from "../Layout/Logout";
import { LogoutOutlined } from "@ant-design/icons";
import MenuHeader from "../../Layout/MenuHeader";
import { FileSearchOutlined,FileSyncOutlined, ProfileOutlined } from '@ant-design/icons';
import Item from "../Components/Item";

const { Text } = Typography;

function OHome() {
  const { Search } = Input;
  const history = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100vh" ,backgroundColor:'white'}}>
      <MenuHeader page={'Home'}/>

    
  
      
      <Flex vertical={isMobile}   gap={16}
     
      style={{ width: "70%", marginLeft: "15% ",marginTop:'3%' }}
      > {/* Flex container with flexDirection set to row */}
        <Item icon={<FileSearchOutlined/>} path={'/opera/home/pedidos'} title={'Gestao de Processos'} text={'Gestao dos pedidos do arquivo!'} />
        <Item icon={<FileSyncOutlined/>} path={'/opera/home/relatorios'} title={'Relatórios e análises'} text={'Monitorar o andamento de todos os processos no tribunal.'} />
        <Item icon={<ProfileOutlined/>} path={'/opera/home/relatorios'} title={'Perfil'} text={'Atualize o seu Perfil.'} />
      </Flex>
        
     
     
    </div>
  );
}

export default OHome;
