import React, { useState, useEffect } from 'react';
import { Layout, Image } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../styles/header.css';

function Header() {
  const { Header } = Layout;
  const [imageWidth, setImageWidth] = useState('100%');
  const [imageMargin, setImageMargin] = useState('0%');
  const history = useNavigate();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setImageWidth('80%');
        setImageMargin('10%');
      } else {
        setImageWidth('70%');
        setImageMargin('15%'); // Adjust margin for centering the image horizontally
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Header
      style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        marginLeft: '5%',
        marginTop: '0%',
        width: '90%',
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        alignItems: 'center', // Center vertically
      }}
    >
      <Image
        preview={false}
        style={{
          width: imageWidth,
          margin: 'auto', // Center the image horizontally
        }}
        src={require('../Assets/img/Group 3 1.png')}
        onClick={() => {
          history('/');
        }}
      />
    </Header>
  );
}

export default Header;
