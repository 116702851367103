import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/account/Login";
import Criar from "./Pages/account/Signup";
import { Layout } from "antd";
import Certidao from "./Pages/guest/Certidao";
import Quitacao from "./Pages/guest/Quitacao";
import Pedidos from "./Pages/guest/Pedidos";
import Historico from "./Pages/guest/Historico";
import AHome from "./Pages/tsadmin/AHome";
import APedidos from "./Pages/tsadmin/APedidos";
import OHome from "./Pages/tsfuncionario/OHome";
import OPedidos from './Pages/tsfuncionario/OPedidos';
import ORelatorios from './Pages/tsfuncionario/ORelatorios';
import PHome from './Pages/Procura/PHome';
import Relatorios from "./Pages/tsadmin/Relatorios";
function App() {
  const { Content } = Layout;

  const contentStyle: React.CSSProperties = {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Layout style={{ overflow: "hidden" }}>
      <Content style={contentStyle}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/criar-conta" element={<Criar />} />
          <Route path="/home" element={<Home />} />
          <Route path="/home/Certidoes" element={<Certidao />} />
          <Route path="/home/Certidoes/quitacao" element={<Quitacao />} />
          <Route path="/home/Certidoes/pedidos" element={<Pedidos />} />
          <Route path="/home/Certidoes/historico" element={<Historico />} />

          <Route path="/admin/home/dash" element={<AHome/>} />
          <Route path="/admin/home/pedidos" element={<APedidos/>} />
          <Route path="/admin/home/relatorios" element={<Relatorios/>} />

          {/*arquivo */}
          <Route path="/opera/home/dash" element={<OHome/>} />
          <Route path="/opera/home/pedidos" element={<OPedidos/>} />
          <Route path="/opera/home/relatorios" element={<ORelatorios/>} />


          <Route path="/procura/home/dash" element={<PHome/>} />


        </Routes>
      </Content>
    </Layout>
  );
}

export default App;
