import React, { useState, useEffect } from "react";
import {
  Input,
  Space,
  Result,
  Button,
  Form,
  Layout,
  Image,
  Steps,
  message,
  Avatar,
  Segmented,
  Descriptions,
  SegmentedProps,
  Flex,
  Typography,
 Row, Col
} from "antd";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  PayCircleOutlined,
  ProfileOutlined,
  CameraOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Header from "../../Layout/Header";
import MenuHeader from "../../Layout/MenuHeader";
function Quitacao() {
  const { Search } = Input;
  const [value, setValue] = useState(1);
  const [phase1, setPhase1] = useState(1);
  const [phase2, setPhase2] = useState(null);
  const [phase4, setPhase4] = useState(null);
  const [step, setStep] = useState(0);
  const [sucesso, setSucesso] = useState(null);
  const [lista,setLista]=useState(null);
  const { Step } = Steps;
  const [dados, setDados] = useState(null);
  const [posicao,setPosicao]=useState(0);
  const [nrcelular,setNrcelular]=useState(84);
  const history = useNavigate();
  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const phoneNumberPattern = /^(84|85)\d{7}$/;

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const validatePhoneNumber = (number) => {
    return phoneNumberPattern.test(number);
  }

  const Pedido_quitacao = () => {
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/Marcar_Pedido/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1,nrcelular:nrcelular },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso invalidos");
        }
        return dat;
      })
      .then((df) => {
        setSucesso(df.data);
        setStep(3);
        setPhase1(null);
        setPhase4("sdsd");
        message.success("Pedido submetido com sucesso!");
      })
      .catch((e) => {
        message.error("Servidor Indisponivel");
      });
  };
  const handleChange_nr = (e) => {
    const inputValue = e.target.value.trim();
  setNrcelular(inputValue);
  };

  useEffect(() => {
    
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/dados_empresa/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1 },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso invalidos");
        }
        return dat;
      })
      .then((df) => {
        setDados(df.data);
        console.log(df.data);
        const d=df.data
        delete d.idEmpresa;
        delete d.user;
        delete d.objectivo;
        delete d.cidade;
        const items = Object.entries(d).map(([key, value]) => ({
          key,
          label: key,
          children: value,
        }));
        setLista(items);
        message.success("dados Carregados com sucesso!");
      })
      .catch((e) => {
        message.error("Servidor Indisponivel");
      });
  }, []);

  const onChangepay: SegmentedProps["onChange"] = (value: string) => {
    console.log(value);
    setPosicao(parseInt(value));
  };
  const renders=[
      {content:   <div className="mt-3" style={{ textAlign: 'right' }}>
      <p className="text-sm mt-2">Insira o seu número de celular para efetuar o pagamento</p>
      <div>
        <Input placeholder="84/85 xxx xxxx"    value={nrcelular}
    
      onChange={handleChange_nr} />
           {!validatePhoneNumber(nrcelular) && (
          <p style={{ color: 'red' }}>Número de telefone inválido. Deve começar com 84 ou 85 seguido de 7 dígitos.</p>
        )}
      </div>
      <Button type="primary" className="mt-1"  style={{backgroundColor:'red'}}  onClick={Pedido_quitacao}  >Pagar</Button>
    </div>
    },

{content:<>
      
      <Button type="primary" className="mt-3" style={{backgroundColor:'orange'}} >Gerar Entidade e Referência</Button>
</>},

{content:<>
      
      <Button type="primary" className="mt-3" style={{backgroundColor:'red'}} >Gerar Entidade e Referência </Button>
</>},

{content: <>
      <Typography.Text>
      brevemente
      </Typography.Text>
  
</>},





  ]

  return (
    <div className="w-full bg-white h-screen">
      <MenuHeader page={'guest'}/>
      <div
        style={{
          width: "80%",
          marginTop: "2%",
          marginLeft: "10%",
          marginBottom: "2%",
        }}
      >
        <Steps current={step}>
          <Step title="Resumo" />

        
          <Step title="Concluido" />
        </Steps>
      </div>
      <div style={{
        width:'80%',
        marginLeft:'10%',
        backgroundColor:'white',
        borderRadius:'8px'
      }}>
        {phase1 && (
          <Space
            className="space"
            size="large"
            align="center"
            direction="vertical"
          >
            <div
              style={{
                color:'#8A593E',
                fontWeight:'700',
                fontSize:'18px',

                
              }}
            >
              Certidão de Quitação
            </div>

            {lista && (
        <>
        <div className="lg:flex lg:flex-row lg:gap-8 mb-10">
          <div className="lg:w-3/5">
            <Descriptions   title="Empresa" items={lista} />
          </div>
          <div className="lg:w-2/5">
          <Typography.Title  level={5}>Método de pagamento</Typography.Title>
            <Segmented
              onChange={onChangepay}
              options={[
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        shape="square"
                        size={64}
                        src={
                          <img src={require("../../Assets/img/ss.png")} />
                        }
                      />
                      <div>M-PESA</div>
                    </div>
                  ),
                  value: 0,
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        shape="square"
                        size={64}
                        src={
                          <img src={require("../../Assets/img//BCI.jpeg")} />
                        }
                      />
                      <div>BCI</div>
                    </div>
                  ),
                  value: 1,
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        shape="square"
                        size={64}
                        src={
                          <img src={require("../../Assets/img/MOZA.png")} />
                        }
                      />
                      <div>MOZA</div>
                    </div>
                  ),
                  value: 2,
                },
                {
                  label: (
                    <div style={{ padding: 4 }}>
                      <Avatar
                        shape="square"
                        size={64}
                        src={
                          <img src={require("../../Assets/img/visa.png")} />
                        }
                      />
                      <div>VISA</div>
                    </div>
                  ),
                  value: 3,
                },
              ]}
            />
            <div>{renders[posicao].content}</div>
          </div>
        </div>
      </>
      
            )}
          </Space>
        )}

     
      </div>

      {phase2 && (
        <>
          <div style={{width:'80%',marginLeft:'10%'}}>
            <Space  style={{marginLeft:'-1%'}} direction="vertical">
              <Segmented
                onChange={onChangepay}
                options={[
                  {
                    label: (
                      <div style={{ padding: 4 }}>
                        <Avatar
                          shape="square"
                          size={64}
                          src={
                            <img src={require("../../Assets/img/ss.png")} />
                          }
                        />
                        <div>M-PESA</div>
                      </div>
                    ),
                    value: "pay1",
                  },
                  {
                    label: (
                      <div style={{ padding: 4 }}>
                        <Avatar
                          shape="square"
                          size={64}
                          src={
                            <img src={require("../../Assets/img//BCI.jpeg")} />
                          }
                        />
                        <div>BCI</div>
                      </div>
                    ),
                    value: "pay2",
                  },
                  {
                    label: (
                      <div style={{ padding: 4 }}>
                        <Avatar
                          shape="square"
                          size={64}
                          src={
                            <img src={require("../../Assets/img/MOZA.png")} />
                          }
                        />
                        <div>MOZA</div>
                      </div>
                    ),
                    value: "pay2",
                  },
             
                ]}
              />
            </Space>
            <div>
              <Descriptions
                title="Pagamento informacoes"
                className="opdc"
                bordered
              >
                <Descriptions.Item label="Servico">
                  Certidao de Quitacao
                </Descriptions.Item>
                <Descriptions.Item label="Valor">1000 MZN</Descriptions.Item>
              </Descriptions>

              <Flex  style={{marginTop:'2%'}} justify="space-between">
                <Button
                  className="avanca1"
                  onClick={() => {
                    setStep(0);
                    setPhase1("dfdf");
                    setPhase2(null);
                  }}
                >
                  <LeftCircleOutlined />
                </Button>

                <Button
                  type="primary"
                  className="aas"
                 
                >
                  {" "}
                  Pagar
                </Button>
              </Flex>
            </div>
          </div>
        </>
      )}

      {phase4 && (
        <>
          <div className="Registado_com_sucesso">
            <Result
              status="success"
              title="Pedido submetido com sucesso!"
              extra={[
                <Button
                  key="buy"
                  onClick={() => {
                    history("/home/Certidoes");
                  }}
                >
                  Menu
                </Button>,
              ]}
            />
            <div className="separador_quitacao"></div>
          </div>
        </>
      )}
    </div>
  );
}
export default Quitacao;
