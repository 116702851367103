import React, { useState, useEffect } from 'react';
import { Layout, Image,Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import '../styles/header.css';
import { PoweroffOutlined,LeftCircleOutlined } from '@ant-design/icons';
function MenuHeader({page}) {
  const { Header } = Layout;
  const [imageWidth, setImageWidth] = useState('100%');
  const [imageMargin, setImageMargin] = useState('0%');
  const history = useNavigate();

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setImageWidth('80%');
        setImageMargin('10%');
      } else {
        setImageWidth('70%');
        setImageMargin('15%'); // Adjust margin for centering the image horizontally
      }
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Header
      style={{
        backgroundColor: 'white',
        borderRadius: '8px',
        marginLeft: '5%',
        marginTop: '3%',
        width: '90%',
        display: 'flex',
  
   
      }}

    >
      <Image
        preview={false}
        style={{
          width: imageWidth,
          margin: 'auto', // Center the image horizontally
        }}
        src={require('../Assets/img/Group 3 1.png')}
      
      />
   <div className="flex items-center justify-end w-full p-2 rounded-lg" style={{ backgroundColor: '#8A593E' }}>
    {page =='Home' ? <>
    <div className="bg-brown-700 rounded-full p-2 mr-0" style={{cursor:'pointer'}}        onClick={() => {
        history('/');
      }} >
        <PoweroffOutlined className="text-white" />
      </div>
      <span className="text-white">Sair</span>
    </> :<></>}
    {page== 'guest'?<>
    
    <div style={{ marginTop: '0%' ,color:'white' ,cursor:'pointer'} }     onClick={() => {
          history("/home/Certidoes");
        }} > 
    
        <LeftCircleOutlined /> Voltar
      
    </div>
    
    </>:<></>}
    {page== 'admin'?<>
    
    <div style={{ marginTop: '0%' ,color:'white' ,cursor:'pointer'} }     onClick={() => {
          history("/opera/home/dash");
        }} > 
    
        <LeftCircleOutlined /> Voltar
      
    </div>
    
    </>:<></>}
    {page== 'arquivo'?<>
    
    <div style={{ marginTop: '0%' ,color:'white' ,cursor:'pointer'} }     onClick={() => {
          history("/procura/home/dash");
        }} > 
    
        <LeftCircleOutlined /> Voltar
      
    </div>
    
    </>:<></>}
 
    
    </div>
    </Header>
  );
}

export default MenuHeader;
