import React, { useEffect, useState, useRef } from "react";
import "../../styles/pedidos.css";
import {
  Input,
  Space,
  Card,
  Button,
  Select,
  message,
  Modal,
  Steps,
  QRCode,
  Layout,
  Flex,Image,
} from "antd";
import {  List } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format, subMonths } from "date-fns";
import { pt } from "date-fns/locale";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  PayCircleOutlined,
  ProfileOutlined,
  CameraOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  UserOutlined,
  CheckOutlined,
  DownloadOutlined,
  LogoutOutlined,
  
} from "@ant-design/icons";
import MenuHeader from "../../Layout/MenuHeader";
import { tr } from "date-fns/locale/tr";
function Pedidos() {
  const { Search } = Input;
  const history = useNavigate();
  const [data, setData] = useState(null);
  const [data_empresa, setData_empresa] = useState(null);
  const { Step } = Steps;
  const [phase1, setPhase1] = useState("sd");
  const [phase2, setPhase2] = useState(null);
  const [text, setText] = useState("https://ant.design/");
  const [modalVisible, setModalVisible] = useState(false);
  const hoje = new Date();
  const htmlRef = useRef(null);
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  useEffect(() => {
    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/pedidos_empresa/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1 },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso invalidos");
        }
        return dat;
      })
      .then((df) => {
        setData(df.data);
        console.log(df.data);
        message.success("dados Carregados com sucesso!");
      })
      .catch((e) => {
        message.error("Servidor Indisponivel");
      });

    axios({
      method: "post",
      url: `${localStorage.getItem("url")}/web/dados_empresa/`,
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      data: { id: 1 },
    })
      .then((dat) => {
        if (dat.status !== 200) {
          throw Error("Dados de acesso invalidos");
        }
        return dat;
      })
      .then((df) => {
        setData_empresa(df.data);
        console.log(df.data);
        message.success("dados Carregados com sucesso!");
      })
      .catch((e) => {
        message.error("Servidor Indisponivel");
      });
  }, []);
  const atribuir = () => {
    const pedo = new Date(phase2.data_submisa);
    const dataPorExtenso_submissao = format(
      pedo,
      "dd 'do mês de' MMMM 'do ano de' yyyy",
      { locale: pt }
    );
    const dataDiminuida = subMonths(pedo, 3);
    const dataPorExtenso_submissao_passdo = format(
      dataDiminuida,
      "dd 'do mês de' MMMM 'do ano de' yyyy",
      { locale: pt }
    );
  };
  const closeModal = () => {
    setModalVisible(false);
  };

  const processingDate = new Date();

  // Formatar a data de processamento
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedProcessingDate = processingDate.toLocaleDateString('pt-BR', options);

  // Calcular e formatar a data de validade (três meses após a data de processamento)
  const validityDate = new Date(processingDate);
  validityDate.setMonth(validityDate.getMonth() + 3);
  const formattedValidityDate = validityDate.toLocaleDateString('pt-BR', options);

  
  const [download, setDownload] = useState(null);
  const handle = (item) => {
    console.log(item)
    if (parseInt(item.estado) == 2) {
      setDownload("wew");
    }
  };

  const handleUpload = () => {
    // Get the HTML element
    const html = htmlRef.current;

    // Create a new instance of jsPDF
    const pdf = new jsPDF();

    // Convert the HTML element to PDF using html2canvas
    html2canvas(html).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        pdf.internal.pageSize.getWidth(),
        pdf.internal.pageSize.getHeight()
      );
      pdf.save(`Quitacao-${data_empresa.nome}.pdf`);

      // Upload the PDF file to the server using Axios
    });
    // Create a FormData object to send the PDF file to the server
  };
  return (
<div className="w-full h-screen flex flex-col mt-0 bg-white">
     <MenuHeader  page={'guest'}/>
    
            {data && (
              <>
<List
  className=" h-full" // Definindo a largura como 90%
  style={{width:'80%', marginLeft:'10%', marginTop:'3%'}}
  grid={{
    gutter: 16,
    xs: 1, // Para dispositivos muito pequenos (como telefones), exibe apenas 1 coluna
    sm: 1, // Para dispositivos pequenos, exibe apenas 1 coluna
    md: 3, // Para dispositivos médios (como notebooks), exibe 3 colunas
    lg: 3, // Para dispositivos grandes, exibe apenas 1 coluna
    xl: 3, // Para dispositivos extra grandes, exibe apenas 1 coluna
    xxl: 3, // Para dispositivos extra, extra grandes, exibe apenas 1 coluna
  }}
  size="small"
  dataSource={data}
  renderItem={(item) => (
    <List.Item  style={{width:'100%'}}> {/* Adicionando a classe w-30 */}
      <Card title={"Pedido de Quitação"}>
        <p>
          <text>Data de submissão:</text>
          {item.data_submisao}
        </p>
        <p>
          <text>Método de Pagamento:</text> {/* Corrigindo a palavra "Método" */}
          Banco
        </p>
        <p>
          <text>Valor:</text>
          1000
        </p>
        <p>
          <text>Estado:</text> {/* Corrigindo a palavra "Estado" */}
          {item.estado_info}
        </p>

        <Button
          onClick={() => {
            handle(item);
            setPhase2(item);
            setModalVisible(true);
          }}
        >
          Ver detalhes
        </Button>
      </Card>
    </List.Item>
  )}
/>



              </>
            )}
      
{phase2 && <>
  <Modal
  title="Resumo do Pedido"
  visible={modalVisible}
  onCancel={closeModal}
  footer={[download? (
    <Button key="download" type="primary" className="stp" onClick={handleUpload}>
      Baixar <DownloadOutlined />
    </Button>
  ):null  ]}
  width={'80%'}
>
<div className="Resumo_pedido">
            <div className="passos_pedidos">
              <Steps current={parseInt(phase2.estado)}  size="small" >
                <Step title="PENDENTE PAGAMENTO" />

                <Step title="EM PRODUÇÃO" />
                <Step title="TERMINADO" />
              </Steps>
            </div>
            <div className="lista_pedidoss-s">
              <Card className="d" title={"Informação do pedido"}>
                <p>
                  <text>Data de submissão:</text>
                  {phase2.data_submisao}
                </p>
                <p>
                  <text>Metodo de Pagamento:</text>

                  {"M-pesa"}
                </p>
                <p>
                  <text>Valor:</text>
                  1000
                </p>
                <p>
                  <text>estado:</text>
                  {phase2.estado_info}
                </p>
              </Card>

              <Card className="d" title={"Informação da Empresa"}>
                <p>
                  <text>Nome:</text>
                  {data_empresa.nome}
                </p>
                <p>
                  <text>Endereco:</text>

                  {data_empresa.endereco}
                </p>
                <p>
                  <text>Nuit:</text>

                  {data_empresa.nuit}
                </p>
                <p>
                  <text>Atividades:</text>
                  {data_empresa.vocacao}
                </p>
              </Card>
            </div>
           
           
          </div>
          <div className="whites"    >
            <div className="A4" ref={htmlRef} >
            <img
        style={{ display: 'block', margin: '0 auto' }}
        src={require("../../Assets/img/rep-removebg-preview.png")}
        alt="República de Moçambique"
      />
     <div className="w-full text-center" style={{fontFamily: 'Century Gothic, sans-serif'}} >
  <h3><strong>República de Moçambique</strong></h3>
  <h3>Tribunal {data_empresa.cidade}</h3>
  <h3>Distribuição - Geral</h3>
  <h3 className="h3numero">
    CERTIDÃO N° 4326/{data_empresa.provinvia}/SG/0{phase2.idPedido}
  </h3>
</div>

              <div className="body"   style={{fontFamily: 'Century Gothic, sans-serif'}}>
                <p style={{fontFamily: 'Century Gothic, sans-serif'}}>
                  SIMIÃO CUNA, Distribuidor Provincial do
                  Tribunal {data_empresa.cidade}.
                </p>
                <p style={{fontFamily: 'Century Gothic, sans-serif'}}>
                CERTIFICA QUE, compulsados os livros de registo de entrada de acções e papéis diversos existentes na Secretaria – Geral do tribunal,{" "}
                  <text>não</text> consta ter dado entrada no período de{" "}
                  {format(
                    subMonths(new Date(phase2.data_submisao), 3),
                    "dd 'do mês de' MMMM 'do ano de' yyyy",
                    { locale: pt }
                  )}{" "}
                  a{" "}
                  {format(
                    new Date(phase2.data_submisao),
                    "dd 'do mês de' MMMM 'do ano de' yyyy",
                    { locale: pt }
                  )}
                  , qualquer pedido de falência, concordata, nem acção judicial contra a empresa {" "}
                  <text>{data_empresa.nome}</text>.
                </p>
                <p style={{fontFamily: 'Century Gothic, sans-serif'}}>
                É tudo quanto me cumpre certificar em face do requerimento da empresa acima referida e do que os autos dos referidos livros reportam.
                </p>

                <p style={{fontFamily: 'Century Gothic, sans-serif'}}>
                Secretaria - Geral do Tribunal Judicial da {data_empresa.cidade},
                  {" "}
                  {format(hoje, "dd 'do mês de' MMMM 'do ano de' yyyy", {
                    locale: pt,
                  })}
                  .
                </p>
              </div>
              <div className="infos_09" style={{fontFamily: 'Century Gothic, sans-serif'}}>
                <text className="a">O Distribuidor Provincial,</text>
              

                <text className="a" style={{margin:'5%'}}>Simião Cuna</text>
              </div>

              <div>
                <QRCode
                  className="Qrcode_place"
                  value={`${localStorage.getItem("url")}/opera/home/documento/${phase2.serialNumber}`}
                />
              </div>
              <div className="Conta" style={{ fontWeight: '400',
    border: '2px dashed black',
    padding: '10px',
    width: '25%',
    marginLeft:'70%' ,
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center', // Center the text
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center' // Center align the cont
    ,fontFamily: 'Century Gothic, sans-serif'
  }}  >
      <div>Conta:</div>
      <div>Fixo --------------- 75.00mt</div>
      <div>Narrativa --------- 20.00mt</div>
      <div>Rasa --------------- 2.50mt</div>
      <div>Busca -------------- 1.50mt</div>
      <div>Papel -------------- 1.00mt</div>
      <div>T.Serviços----------- 20.00mt</div>
      <div>Total -------------- 120.00mt</div>
    </div>

    <div style={{ fontFamily: 'Century Gothic, sans-serif', fontSize: '8px' }}>
      <p>Este documento foi processado pelo TSCOB em {formattedProcessingDate}.</p>
      <p>Valido ate {formattedValidityDate}</p>
    </div>

    <div></div>
            </div>
          </div>

</Modal>
</>  }


      
  </div>
  
  );
}

export default Pedidos;
